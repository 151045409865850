<div class="container small-container pb-5" *ngIf="materielForm">

  <div class="page-menu">
    <button class="btn btn-light"
            (click)="materielForm.dirty ? modalService.open(ignorerModificationMaterielModal) : ignorerModificationMateriel()">
      <i class="bi bi-chevron-left"></i><span class="ms-2">Retour</span>
    </button>
    <div>
      <ng-container *ngIf="!materielId && selectedTab === 'à-réceptionner'">Ajout d'un matériel à réceptionner
      </ng-container>
      <ng-container *ngIf="!materielId && selectedTab === 'sur-chantier'">Ajout d'un matériel sur chantier
      </ng-container>

      <ng-container *ngIf="materielId && selectedTab === 'à-réceptionner'">Modification d'un matériel à réceptionner
      </ng-container>
      <ng-container *ngIf="materielId && selectedTab === 'sur-chantier'">Modification d'un matériel sur chantier
      </ng-container>
    </div>
  </div>

  <form [formGroup]="materielForm" (ngSubmit)="saveMateriel()" autocomplete="off">
    <div class="d-flex justify-content-center px-3">
      <ng-container *ngIf="photoPreview">
        <img class="img-fluid rounded" [src]="'data:image/jpeg;base64,' + photoPreview">
      </ng-container>
      <ng-container *ngIf="!photoPreview">
        <img *ngIf="photoData" class="img-fluid rounded" [src]="photoData">
        <img *ngIf="!photoData && photoCategorieData" class="img-fluid rounded"
             [src]="photoCategorieData">
        <img *ngIf="!photoData && !photoCategorieData" class="img-fluid rounded"
             src="assets/images/photos/materiel-photo-placeholder.png" style="max-width: 220px;">
      </ng-container>
    </div>

    <div *ngIf="deviceService.isMobile() || deviceService.isTablet()" class="text-end">
      <input #photoInput hidden="true" type="file" accept="image/*" (change)="photoInputChange($event)" />
      <button type="button" class="btn btn-camera" (click)="photoInput.click()"
              [disabled]="photoIsLoading || formIsSubmitting">
        <i class="bi bi-camera" *ngIf="!photoIsLoading && !formIsSubmitting"></i>
        <div *ngIf="photoIsLoading || formIsSubmitting"
             class="col-3 text-center spinner-border text-danger"></div>
      </button>
    </div>

    <div class="mb-3">
      <label for="nom" class="form-label">Nom<span class="required">*</span></label>
      <input type="text" class="form-control" id="nom" formControlName="nom"
             [ngClass]="materielForm.get('nom').invalid && (materielForm.get('nom').dirty || materielForm.get('nom').touched) ? 'is-invalid' : ''">
      <div class="invalid-feedback">
        <div *ngIf="materielForm.get('nom').errors?.['required']"> Le nom est obligatoire</div>
        <div *ngIf="materielForm.get('nom').errors?.['maxlength']"> Le nom doit faire moins de 50 caractères</div>
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label" for="categorie">Catégorie<span class="required">*</span></label>
      <select class="form-select" id="categorie" formControlName="codeCategorie"
              [ngClass]="materielForm.get('codeCategorie').invalid && (materielForm.get('codeCategorie').dirty || materielForm.get('codeCategorie').touched) ? 'is-invalid' : ''">
        <option *ngFor="let categorie of categories" [ngValue]="categorie.code"
                [selected]="materielForm.get('codeCategorie').value && categorie.code === materielForm.get('codeCategorie').value">
          {{ categorie.nom }}
        </option>
      </select>
      <div class="invalid-feedback">
        <div *ngIf="materielForm.get('codeCategorie').errors?.['required']"> La catégorie est obligatoire</div>
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label" for="codeLocatier">Locatier<span class="required">*</span></label>
      <ng-select id="codeLocatier" formControlName="codeLocatier" [clearable]="false"
                 [ngClass]="materielForm.get('codeLocatier').invalid && (materielForm.get('codeLocatier').dirty || materielForm.get('codeLocatier').touched) ? 'is-invalid' : ''">
        <ng-option *ngFor="let locatier of locatiers" [value]="locatier.code">{{locatier.nom}}</ng-option>
      </ng-select>
      <div class="invalid-feedback">
        <div *ngIf="materielForm.get('codeLocatier').errors?.['required']">Le locatier est obligatoire</div>
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label" for="numCommande">N° commande</label>
      <input id="numCommande" type="text" formControlName="numCommande" class="form-control"
             [ngClass]="materielForm.get('numCommande').invalid && (materielForm.get('numCommande').dirty || materielForm.get('numCommande').touched) ? 'is-invalid' : ''">
      <div class="invalid-feedback">
        <div *ngIf="materielForm.get('numCommande').errors?.['max']">
          Le numéro de commande doit faire moins de 25 caractères
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label" for="numParc">N° parc</label>
      <input id="numParc" type="text" formControlName="numParc" class="form-control"
             [ngClass]="materielForm.get('numParc').invalid && (materielForm.get('numParc').dirty || materielForm.get('numParc').touched) ? 'is-invalid' : ''">
      <div class="invalid-feedback">
        <div *ngIf="materielForm.get('numParc').errors?.['maxlength']"> Le numéro de parc doit faire moins de 25
          caractères
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label" for="numSerie">N° de série</label>
      <input id="numSerie" type="text" formControlName="numSerie" class="form-control"
             [ngClass]="materielForm.get('numSerie').invalid && (materielForm.get('numSerie').dirty || materielForm.get('numSerie').touched) ? 'is-invalid' : ''">
      <div class="invalid-feedback">
        <div *ngIf="materielForm.get('numSerie').errors?.['maxlength']">
          Le numéro de série doit faire moins de 50 caractères
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label">Périodicité VGP</label>
      <div class="btn-group d-block" role="group">
        <input type="radio" class="btn-check" formControlName="periodeVGP" id="inputVGP" [value]="null">
        <label class="btn btn-outline-dark" for="inputVGP">Aucune</label>

        <input type="radio" class="btn-check" formControlName="periodeVGP" id="inputVGP6" value="6">
        <label class="btn btn-outline-dark" for="inputVGP6">6 mois</label>

        <input type="radio" class="btn-check" formControlName="periodeVGP" id="inputVGP12" value="12">
        <label class="btn btn-outline-dark" for="inputVGP12">12 mois</label>
      </div>
    </div>

    <div class="mb-3" *ngIf="materielForm.get('periodeVGP').value">
      <label class="form-label" for="dateVGP">Date de la dernière VGP<span class="required">*</span></label>
      <input id="dateVGP" type="date" formControlName="dateVGP" max="{{today}}" class="form-control"
             [ngClass]="materielForm.get('dateVGP').invalid && (materielForm.get('dateVGP').dirty || materielForm.get('dateVGP').touched) ? 'is-invalid' : ''">
      <div class="invalid-feedback">
        <div *ngIf="materielForm.get('dateVGP').errors?.['required']">
          La date de dernière VGP doit être saisie si une périodicité VGP a été sélectionnée
        </div>
        <div *ngIf="materielForm.get('dateVGP').errors?.['dateInFuture']"> La date VGP ne doit pas être dans le futur
        </div>
      </div>
    </div>

    <div class="mb-3" *ngIf="selectedTab === 'sur-chantier'">
      <label class="form-label" for="dateReception">Date de réception<span class="required">*</span></label>
      <input id="dateReception" type="date" formControlName="dateReception" max="{{today}}" class="form-control"
             [ngClass]="materielForm.get('dateReception').invalid && (materielForm.get('dateReception').dirty || materielForm.get('dateReception').touched) ? 'is-invalid' : ''">
      <div
        class="invalid-feedback">
        <div *ngIf="materielForm.get('dateReception').errors?.['required']">
          La date de réception est obligatoire
        </div>
        <div *ngIf="materielForm.get('dateReception').errors?.['dateInFuture']">
          La date de réception ne doit pas être dans le futur
        </div>
      </div>
    </div>

    <div class="mb-3" *ngIf="selectedTab === 'à-réceptionner'">
      <label class="form-label" for="dateReceptionEstimee">Date de réception estimée<span
        class="required">*</span></label>
      <input id="dateReceptionEstimee" type="date" formControlName="dateReceptionEstimee" min="{{today}}"
             class="form-control"
             [ngClass]="materielForm.get('dateReceptionEstimee').invalid && (materielForm.get('dateReceptionEstimee').dirty || materielForm.get('dateReceptionEstimee').touched) ? 'is-invalid' : ''">
      <div
        class="invalid-feedback">
        <div *ngIf="materielForm.get('dateReceptionEstimee').errors?.['required']">
          La date de réception estimée est obligatoire
        </div>
        <div *ngIf="materielForm.get('dateReceptionEstimee').errors?.['dateInPast']">
          La date de réception estimée ne doit pas être dans le passé
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label" for="dateSortie">Date de sortie prévisionnelle</label>
      <input id="dateSortie" type="date" formControlName="dateSortie" min="{{minDateSortie}}" class="form-control"
             [ngClass]="materielForm.get('dateSortie').invalid && (materielForm.get('dateSortie').dirty || materielForm.get('dateSortie').touched) ? 'is-invalid' : ''">
      <div class="invalid-feedback">
        <div *ngIf="materielForm.get('dateSortie').errors?.['dateSortieBeforeDateReception']">
          La date de sortie prévisionnelle doit être supérieure à la date de réception
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label>Location à<span class="required">*</span></label>
      <div class="btn-group d-block" role="group"
           [ngClass]="materielForm.get('uniteTemps').invalid && (materielForm.get('uniteTemps').dirty || materielForm.get('uniteTemps').touched) ? 'is-invalid' : ''">
        <input type="radio" class="btn-check" formControlName="uniteTemps" id="uniteTempsM" value="MOIS">
        <label class="btn btn-outline-dark" for="uniteTempsM">Mois</label>

        <input type="radio" class="btn-check" formControlName="uniteTemps" id="uniteTempsJ" value="JOURNEE">
        <label class="btn btn-outline-dark" for="uniteTempsJ">Journée</label>

        <input type="radio" class="btn-check" formControlName="uniteTemps" id="uniteTempsH" value="HEURE">
        <label class="btn btn-outline-dark" for="uniteTempsH">Heure</label>
      </div>

      <div class="invalid-feedback">
        <div *ngIf="materielForm.get('uniteTemps').errors?.['required']"> L'unité de temps est obligatoire</div>
      </div>
    </div>

    <div class="mb-3">
      <label
        for="tarifLocation">{{ 'Tarif matériel (€ / ' + (materielForm.get('uniteTemps').value === 'MOIS' ? 'mois' : ((materielForm.get('uniteTemps').value === 'JOURNEE' ? 'jour' : 'heure'))) + ')'}}</label>
      <input id="tarifLocation" type="number" formControlName="tarifLocation" class="form-control"
             [ngClass]="materielForm.get('tarifLocation').invalid && (materielForm.get('tarifLocation').dirty || materielForm.get('tarifLocation').touched) ? 'is-invalid' : ''">
      <div class="invalid-feedback">
        <div *ngIf="materielForm.get('tarifLocation').errors?.['pattern']">
          Le tarif doit être au format 123 ou 123.12
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label
        for="tarifTransportAller">{{ 'Tarif transport aller (€)'}}</label>
      <input id="tarifTransportAller" type="number" formControlName="tarifTransportAller" class="form-control"
             [ngClass]="materielForm.get('tarifTransportAller').invalid && (materielForm.get('tarifTransportAller').dirty || materielForm.get('tarifTransportAller').touched) ? 'is-invalid' : ''">
      <div class="invalid-feedback">
        <div *ngIf="materielForm.get('tarifTransportAller').errors?.['pattern']">
          Le tarif doit être au format 123 ou 123.12
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label
        for="tarifTransportRetour">{{ 'Tarif transport retour (€)'}}</label>
      <input id="tarifTransportRetour" type="number" formControlName="tarifTransportRetour" class="form-control"
             [ngClass]="materielForm.get('tarifTransportRetour').invalid && (materielForm.get('tarifTransportRetour').dirty || materielForm.get('tarifTransportRetour').touched) ? 'is-invalid' : ''">
      <div class="invalid-feedback">
        <div *ngIf="materielForm.get('tarifTransportRetour').errors?.['pattern']">
          Le tarif doit être au format 123 ou 123.12
        </div>
      </div>
    </div>

    <ng-container *ngIf="selectedTab === 'sur-chantier' && !materielId">
      <div class="form-check form-switch mb-3">
        <input class="form-check-input" type="checkbox" role="switch" id="show-composant"
               [(ngModel)]="composantsCardIsVisible" [ngModelOptions]="{standalone: true}"
               [disabled]="!!composants.controls.length">
        <label class="form-check-label" for="show-composant">Gérer les composants</label>
      </div>

      <div class="mb-3" *ngIf="composantsCardIsVisible">
        <label class="form-label">
          Composants
          <span *ngIf="materielForm.get('dateReception').value">
            présents le {{materielForm.get('dateReception').value | date: 'dd.MM.yyyy'}}
          </span>
        </label>
        <div class="card" formArrayName="composants">
          <div class="card-body px-2">
            <table class="mb-2 w-100" *ngIf="composants.controls.length">
              <thead>
              <tr>
                <th>Nom</th>
                <th style="width: 60px">Qté</th>
                <th style="width: 60px">Unité</th>
                <th style="width: 80px">P.U.</th>
                <th style="width: 33px;"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let composantForm of composants.controls; let i = index" [formGroup]="composantForm">
                <td>
                  <input type="text" class="form-control form-control-sm form-control-composant" formControlName="nom"
                         [ngClass]="composantForm.get('nom').invalid && (composantForm.get('nom').dirty || composantForm.get('nom').touched) ? 'is-invalid' : ''">
                </td>
                <td>
                  <input type="number" class="form-control form-control-sm form-control-composant"
                         formControlName="quantite"
                         [ngClass]="composantForm.get('quantite').invalid && (composantForm.get('quantite').dirty || composantForm.get('quantite').touched) ? 'is-invalid' : ''">
                </td>
                <td>
                  <select class="form-select form-select-sm form-control-composant" formControlName="unite"
                          [ngClass]="composantForm.get('unite').invalid && (composantForm.get('unite').dirty || composantForm.get('unite').touched) ? 'is-invalid' : ''">
                    <option value="KG">Kg</option>
                    <option value="PIECE">Pce</option>
                  </select>
                </td>
                <td>
                  <div class="input-group input-group-sm">
                    <input type="number" class="form-control form-control-composant" formControlName="prixUnitaire"
                           [ngClass]="composantForm.get('prixUnitaire').invalid && (composantForm.get('prixUnitaire').dirty || composantForm.get('prixUnitaire').touched) ? 'is-invalid' : ''">
                    <span class="input-group-text" style="padding-left: 2px;padding-right: 2px;">€</span>
                  </div>
                </td>
                <td>
                  <button class="btn btn-sm btn-primary" type="button" (click)="removeComposant(i)">
                    <i class="bi bi-dash-lg"></i>
                  </button>
                </td>
              </tr>
              <tr *ngIf="showErrorComposant()">
                <td colspan="5">
                  <div class="invalid-feedback d-block">
                    Erreurs détectées dans le formulaire
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div *ngIf="!composants.controls.length" class="text-center mb-2 fst-italic">Cliquer pour ajouter un composant</div>
            <div class="text-center">
              <button class="btn btn-primary" type="button" (click)="addComposant()">
                <i class="bi bi-plus-lg"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="mb-3">
      <label class="form-label" for="commentaire">Commentaire</label>
      <textarea id="commentaire" rows="4" formControlName="commentaire" class="form-control"
                [ngClass]="materielForm.get('commentaire').invalid && (materielForm.get('commentaire').dirty || materielForm.get('commentaire').touched) ? 'is-invalid' : ''"></textarea>
      <div class="invalid-feedback">
        <div *ngIf="materielForm.get('commentaire').errors?.['maxlength']">
          Le commentaire doit faire moins de 255 caractères
        </div>
      </div>
    </div>

    <div class="d-flex flex-column">
      <button type="submit" class="btn btn-danger py-3 my-3"
              [disabled]="formIsSubmitting || photoIsLoading">
        <span *ngIf="formIsSubmitting || photoIsLoading" class="spinner-border"></span>
        <span *ngIf="!formIsSubmitting && !photoIsLoading">Enregistrer</span>
      </button>

      <button type="button" class="btn btn-outline-secondary py-2"
              (click)="materielForm.dirty ? modalService.open(ignorerModificationMaterielModal) : ignorerModificationMateriel()"
              [disabled]="formIsSubmitting">
        Annuler
      </button>
    </div>
  </form>

  <div class="text-center mb-3">
    <button class="btn btn-sm btn-outline-secondary btn-no-border py-3" (click)="scrollToTop()">
      Retour en haut <i class="bi bi-chevron-up"></i>
    </button>
  </div>
</div>

<ng-template #ignorerModificationMaterielModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Ignorer les modifications ?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div>Toutes les modifications du matériel seront perdues.</div>
  </div>
  <div class="modal-footer d-flex flex-column align-items-stretch">
    <button type="button" class="btn btn-primary" (click)="ignorerModificationMateriel()">Ignorer</button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Annuler</button>
  </div>
</ng-template>

<ng-template #renseignerEtatDesLieuxEntreeModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title-edl-entree">Procéder à l'état des lieux d'entrée ?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="nePasRenseignerEtatDesLieuxEntree()"></button>
  </div>
  <div class="modal-body">
    <div>Un formulaire d'état des lieux d'entrée sera à renseigner.</div>
  </div>
  <div class="modal-footer d-flex flex-column align-items-stretch">
    <button type="button" class="btn btn-primary" (click)="renseignerEtatDesLieuxEntree()">Renseigner</button>
    <button type="button" class="btn btn-outline-secondary" (click)="nePasRenseignerEtatDesLieuxEntree()">Ne Pas Renseigner
    </button>
  </div>
</ng-template>
